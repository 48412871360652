import React, { useEffect } from 'react'
import { instanceAPI } from '../../Objects/context'
const listInAppPackages = () => {
    useEffect(()=>{
        instanceAPI.get('com.moovi.android/inappproducts/moovi.inapp.android.onetime.prod.30')
        .then(res=>{
           console.log(res)
          }).catch(err=>{
           console.log(err)
          })
    },[])
  return (
    <div>listInAppPackages</div>
  )
}

export default listInAppPackages